import React from 'react';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { useCurrentCustomCorporatePageBySlugQuery } from '@/apollo/generated';
import GovernancePageContent from '@/components/governance';
import GovernancePageLoadingScreen from '@/components/governance/loading-screen';
import PageHeader from '@/components/layouts/page-header';
import RootLayout from '@/components/layouts/root';
import Website from '@/components/website';
import Website404 from '@/components/website/page/404';
import {
  useCurrentCompany,
  withCurrentCompanyContext,
} from '@/contexts/current-company-context';
import { useWebsite } from '@/contexts/website-context';
import requireCurrentCompany from '@/utils/require-current-company';

const CompanyCustomPage: React.ComponentType = () => {
  const {
    query: { slug: pageSlug },
  } = useRouter();
  const {
    currentCompany: { name },
    currentCompanyMarketKey,
    currentCompanyTicker,
  } = useCurrentCompany();
  const { currentWebsite } = useWebsite();

  const {
    data: currentCustomCorporatePageBySlugData,
    loading: currentCustomCorporatePageBySlugLoading,
  } = useCurrentCustomCorporatePageBySlugQuery({
    skip: !pageSlug || !!currentWebsite,
    variables: {
      listingKey: currentCompanyTicker,
      marketKey: currentCompanyMarketKey.toLowerCase(),
      slug: pageSlug as string,
    },
  });

  if (currentWebsite) {
    return <Website />;
  }
  const renderContent = () => {
    if (currentCustomCorporatePageBySlugLoading) {
      return <GovernancePageLoadingScreen />;
    }

    if (
      currentCustomCorporatePageBySlugData &&
      currentCustomCorporatePageBySlugData.currentCustomCorporatePageBySlug &&
      currentCustomCorporatePageBySlugData.currentCustomCorporatePageBySlug
        .isLive
    ) {
      return (
        <>
          <PageHeader
            headerText={
              currentCustomCorporatePageBySlugData
                .currentCustomCorporatePageBySlug.title
            }
          />
          <div className="mx-auto grid w-full max-w-screen-xl grid-cols-1 gap-8 px-4 py-8 md:gap-12 md:py-12 ">
            <GovernancePageContent
              currentGovernancePage={
                currentCustomCorporatePageBySlugData.currentCustomCorporatePageBySlug
              }
            />
          </div>
        </>
      );
    }
    return <Website404 />;
  };

  return (
    <RootLayout>
      <NextSeo
        description={`View and download all documents related to custom corporate page and policies for ${currentCompanyMarketKey}:${currentCompanyTicker}.`}
        title={`${name} investor hub | Corporate Custom Page`}
      />
      {renderContent()}
    </RootLayout>
  );
};

export const getServerSideProps = requireCurrentCompany({
  getServerSideProps: async (context, { currentCompany }) => {
    const hasCurrentCustomCorporatePage =
      !!currentCompany.currentCustomCorporatePageBySlug?.isLive;
    const hasCurrentWebsitePage =
      !!currentCompany.publishedWebsite?.currentPage;
    const hasPublishedWebsite = !!currentCompany.publishedWebsite;

    if (hasPublishedWebsite && !hasCurrentWebsitePage) {
      // This is the hubs 2.0 404 page
      context.res.statusCode = 404;
    } else if (!hasPublishedWebsite && !hasCurrentCustomCorporatePage) {
      // This is the hubs 1.0 404 page
      context.res.statusCode = 404;
    }

    return {
      props: { currentCompany },
    };
  },
});

export default withCurrentCompanyContext(CompanyCustomPage);
